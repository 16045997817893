import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAsIlLrQRnAwTHhPmjdf7A1EQaPj3o4MCA",
  authDomain: "nexo-ta.firebaseapp.com",
  projectId: "nexo-ta",
  storageBucket: "nexo-ta.appspot.com",
  messagingSenderId: "490353527380",
  appId: "1:490353527380:web:46eab23ff85e66ecf43bc1",
  measurementId: "G-MVM1NZQTMX",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const storage = getFirestore(app);

// Dummy to get firebase executed
export const initFirebase = () => {
  console.log(`Initializing firebase..`);
};

// const GetData = async () => {
//   const querySnapshot = await getDocs(collection(db, "currencies"));
//   querySnapshot.forEach((doc) => {
//     const data = doc.data();
//     console.log(
//       `${doc.id} => ${
//         data.symbol + " " + data["coingecko-id"] + " " + data.type
//       }`
//     );
//   });
// };
